
<template>
    <div class="flex flex-col">
        <!-- Search field -->
        <div class="flex gap-1">
            <div class="relative flex-grow">
                <input type="text" placeholder="Kerko klient..." class="border border-slate-300 p-1  text-xs"
                @keyup="start_search_customer()" v-model="useMainSearchStore().search_customer_val">
                <span class="absolute bottom-3 right-4  flex items-center">
                <i class="fa fa-search fa-xs"></i>
                </span>
            </div>
            <div>
                <select class="border border-slate-300 text-14px" style="height:26px;" v-model="useMainSearchStore().selected_customer_type"
                @change="set_selected_pos()">
                <option v-for="(pos, index) in useMainSearchStore().customers_type_list" :key="index" :value="pos.guid">{{ pos.name }}</option>
                </select>
            </div>
        </div>
        <div>
            <div v-if="useMainSearchStore().show_customers_search_results" class="bg-white"
                style="max-height:55vh; overflow:auto;">
                <div v-for="(result, index) in useMainSearchStore().customer_search_results" :key="index" >
                <div class="product border p-1 hover:bg-sky-200 flex items-center justify-between">
                    <span @click="useMainSearchStore().select_result_customer(result)" class="flex-grow cursor-pointer">
                    {{ result?.firstname + ' ' + result?.lastname }}
                    </span>
                </div>
                </div>
            </div>
            <div v-if="useMainSearchStore().selected_customer && useMainSearchStore().selected_customer.id" class="">
                <div class="tile is-ancestor my-0">
                    <div class="tile is-parent is-4">
                        <article class="tile is-child border border-slate-200 box p-2">
                            <p class="title is-size-6 mb-3 text-center flex flex-col">
                                <span>
                                    {{useMainSearchStore().selected_customer.firstname + ' ' + useMainSearchStore().selected_customer.lastname}}
                                </span>
                                <span>
                                    <div class="text-10px text-slate-400">Kodi: {{useMainSearchStore().selected_customer.code}}</div>
                                </span>
                            </p>
                            <div class="flex flex-col">
                                <div class="flex justify-between text-12px">
                                    <div class="flex flex-col">
                                        <div class="text-10px text-slate-400">Ditelindja</div>
                                        <div v-if="useMainSearchStore().selected_customer.dob">{{useMainSearchStore().formatdob(useMainSearchStore().selected_customer.dob)}}</div>
                                    </div>
                                    <div class="flex flex-col text-right">
                                        <div class="text-10px text-slate-400">Qyteti</div>
                                        <div>{{useMainSearchStore().selected_customer.cityname}}</div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent is-4">
                        <article class="tile is-child border border-slate-200 box p-2">
                            <p class="title is-size-6 mb-3 text-center flex flex-col">
                                <span>
                                    Ditare
                                </span>
                            </p>
                            <table 
                             class="table is-striped is-fullwidth is-narrow is-bordered text-12px">
                                <thead>
                                    <tr>
                                        <td>Nr</td>
                                        <td>POS</td>
                                        <td>Date</td>
                                        <td>#</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(diary, index) in useMainSearchStore().selected_customer.diary_list" :key="index">
                                        <td>{{diary.id}}</td>
                                        <td>{{diary.pos_name}}</td>
                                        <td>
                                            {{useMainSearchStore().formatDate(diary.created_at)}}
                                        </td>
                                        <td style="text-align:center;">
                                            <a @click.prevent="openDiary(diary.guid)" href="#" class="bg-teal-400 border rounded text-white px-1">
                                                <i class="fa-regular fa-share-from-square"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </article>
                    </div>
                    <div class="tile is-parent is-4">
                        <article class="tile is-child border border-slate-200 box p-2">
                            <p class="title is-size-6 mb-3 text-center flex flex-col">
                                <span>
                                    Blerje
                                </span>
                            </p>
                            <div class="flex flex-col text-14px">
                                <div class="flex justify-between border-b">
                                    <div class="flex flex-col">
                                        <span> Product name </span>
                                        <span class="text-8px text-slate-400">
                                            Code: 545sdsd5crr83294ssd</span>
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-check text-green-400"></i>
                                    </div>
                                </div>
                                <div class="flex justify-between border-b">
                                    <div class="flex flex-col">
                                        <span> Product name 1 </span>
                                        <span class="text-8px text-slate-400">
                                            Code: 545sdsd5crr83294ssd</span>
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-hourglass-half text-blue-500"></i>
                                    </div>
                                </div>
                                <div class="flex justify-between border-b">
                                    <div class="flex flex-col">
                                        <span> Product name 2 </span>
                                        <span class="text-8px text-slate-400">
                                            Code: 545sdsd5crr83294ssd</span>
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-truck text-green-500"></i>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor mt-0 ">
                    <div class="tile is-parent is-6">
                        <article class="tile is-child border border-slate-200 box p-2">
                            <p class="title is-size-6 mb-3 text-center flex flex-col">
                                <span> Limiti kreditor </span>
                                <span class="text-10px text-slate-400">Limiti i kreditimit te nje klienti</span>
                            </p>
                            <div class="flex flex-col">
                                <div class="flex justify-between text-12px">
                                    <div class="flex flex-col">
                                        <div class="text-10px text-slate-400">Caktiar me</div>
                                        <div>20/5/2022</div>
                                    </div>
                                    <div class="flex flex-col text-right">
                                        <div class="text-10px text-slate-400">Caktuar nga</div>
                                        <div>User name</div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent is-6">
                        <article class="tile is-child border border-slate-200 box p-2">
                            <p class="title is-size-6 mb-3 text-center flex flex-col">
                                <span>
                                    Gjendja debitore
                                </span>
                                <span class="text-10px text-slate-400">Gjendja debitore e klientit </span>
                            </p>
                            <table class="table is-striped is-fullwidth is-narrow is-bordered text-14px">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>POS</td>
                                        <td>Date</td>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </article>
                    </div>
                    
                </div>
                <div class="tabs is-fullwidth is-boxed is-size-7 bg-slate-50">
                    <ul>
                    <li class="is-active bg-white"><a class="bg-white">Porosi</a></li>
                    <li><a>Rezervime</a></li>
                    <li><a>Garanci</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    methods:{
        start_search_customer() {
            if (useMainSearchStore().search_customer_val.length >= 3) {
                // Reset search results
                // this.expand_search_result = false
                // this.selected_search_result = {}
                useMainSearchStore().search_product_customer()
            }
        },
    }
}
</script>
<script setup>
    // import { reactive, onMounted, onBeforeUnmount , ref, inject, watch } from 'vue'
    import { onMounted } from 'vue'
    import { useRouter } from "vue-router";
    const router = useRouter();
    // Stores import
    // import { useSearchProcess } from '@/stores/search_process'
    // import { useDiaryStore } from '@/stores/diary'
    import { useMainSearchStore } from '@/stores/main_search'

    const openDiary = (id) => {
        router.push({ name: "Ditar Shiko", params: { id } });
    };
    onMounted(async () => {

    })
</script>
<style></style>