<template>
  <div>
    <div class="flex justify-center">
      <div class="flex flex-col justify-center"
        :class="[!diary ? 'sm:w-full md:w-2/3' : 'w-full']">
      <!-- Product - costomer tabs -->
        <div v-if="!diary" class="flex justify-around mb-3">
          <div @click="useMainSearchStore().toggle_tab(1)"
            :class="[useMainSearchStore().search_tab == 1 ? 'border-2 border-slate-500 font-semibold' : 'border' ]"
           class="flex justify-center items-center py-2 w-32 rounded cursor-pointer">Produkte</div>
          <div @click="useMainSearchStore().toggle_tab(2)" 
            :class="[useMainSearchStore().search_tab == 2 ? 'border-2 border-slate-500 font-semibold' : 'border' ]"
          class="flex justify-center items-center py-2 w-32 rounded cursor-pointer">Kliente</div>
        </div>
        <customer-search-tab 
          v-if="useMainSearchStore().search_tab == 2 && !diary"
        ></customer-search-tab>
        <div v-if="useMainSearchStore().search_tab == 1 "
          class="w-full flex flex-col">
          <!-- Search field -->
          <div class="flex gap-1">
            <div class="relative flex-grow">
              <input type="text" placeholder="Kerko produktin..." class="border border-slate-300 p-1  text-xs"
                @keyup="start_search()" v-model="useMainSearchStore().search_val">
              <span class="absolute bottom-3 right-4  flex items-center">
                <i class="fa fa-search fa-xs"></i>
              </span>
            </div>
            <div v-if="!diary">
              <select class="border border-slate-300 text-14px" style="height:26px;" v-model="useMainSearchStore().selected_pos_id"
                @change="set_selected_pos()">
                <option v-for="(pos, index) in posses_list" :key="index" :value="pos.guid">{{ pos.name }}</option>
              </select>
            </div>
          </div>
          <!-- Search results list -->
          <div v-if="useMainSearchStore().search_results.length > 0" class="bg-white"
            style="max-height:55vh; overflow:auto;">
            <div v-for="(result, index) in useMainSearchStore().search_results" :key="index" :class="[
              result?.expanded ? 'border-4 border-green-500' : '',
              result?.amount > 0 ? 'bg-green-50' : ''
            ]">
              <div class="product border p-1 hover:bg-sky-200 flex items-center justify-between"
                :class="[result?.expanded ? 'bg-green-50 border-b-2 border-t-2 border-green-600' : '']">
                <span @click="select_result_product(result)" class="flex-grow cursor-pointer">
                  {{ result?.name }}
                </span>
              </div>
            </div>
          </div>

          <!-- Products cart -->
          <div v-if="useMainSearchStore().products_cart.length > 0" class="mt-2">
            <table class="table is-narrow is-bordered text-14px is-fullwidth mb-2">
              <thead>
              </thead>
              <tbody>
                <tr class="text-slate-400">
                  <td>Product</td>
                  <td>POS</td>
                  <td>Magazina</td>
                  <td>Sasia Tot.</td>
                  <td>Sasia</td>
                  <td>#</td>
                </tr>
                <tr v-for="(item, index) in useMainSearchStore().products_cart" :key="index"
                  >
                  <td>{{item.product.name}}</td>
                  <td> {{item.pos_name}} </td>
                  <td>
                    <span v-if="item.is_same_pos">
                      {{ 
                        item.warehouse_type == 1 ? 'Stock per shitje' : 
                        item.warehouse_type == 2 ? 'Rezervime' : 
                        item.warehouse_type == 3 ? 'Vetrine' : 
                        item.warehouse_type == 4 ? 'P. Demtuara' : ''
                      }}
                    </span>
                    <span v-else>
                      {{ item.warehouse?.warehouse_name }}
                    </span>
                  </td>
                  <td>{{ useDiaryStore().amount_filter(item.warehouse?.amount) }}</td>
                  <td>
                      <!-- @input="() => { if(value > item.warehouse.amount || value < 0) { value = item.warehouse.amount }}" -->
                    <input v-model="item.product.requested_amount" :min="0" 
                      @input="() => { if(item.product.requested_amount > item.warehouse.amount || item.product.requested_amount < 0) { item.product.requested_amount = item.warehouse.amount }}"
                      :max="item.warehouse.amount" 
                      type="number"/>
                  </td>
                  <td>
                    <a @click.prevent="useMainSearchStore().remove_cart_item(item)" 
                     class="text-red-500 text-12px" href=""> 
                      <i class="fa-solid fa-trash-can"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <pre> {{useMainSearchStore().products_cart}} </pre> -->
            <div class="flex items-right">
              <a @click.prevent="useMainSearchStore().add_products(diary)" 
                class="ml-auto text-14px bg-green-200 border border-green-700 rounded px-2" href="">
                Shto produktet
              </a>
            </div>
          </div>


          <!-- Selected result -->
          <div v-if="selected_search_result.product" @click="expand_result()"
            class="border border-slate-400 bg-slate-200 flex mt-2 items-center text-14px cursor-pointer">
            <div class="flex-grow px-1" style="">
              {{ selected_search_result.product.name }}
            </div>
            <div class="text-center flex flex-col" style="width:10%;">
              <span class="text-10px">Stok i lire</span>
              {{ useMainSearchStore().amount_format(selected_search_result.product.free_stock_amount) }}
            </div>
            <div class="text-center flex flex-col" style="width:10%;">
              <span class="text-10px">Vetrine</span>
              <!-- {{selected_search_result.total_vetrine}} -->
              {{ useMainSearchStore().amount_format(selected_search_result.product.showcase_amount) }}
            </div>
            <div class="text-center flex flex-col" style="width:10%;">
              <span class="text-10px">P.demtuara</span>
              <!-- {{selected_search_result.total_reserved}} -->
              {{ useMainSearchStore().amount_format(selected_search_result.product.broken_stock_amount) }}
            </div>
            <div class="text-center flex flex-col" style="width:13%;">
              <span class="text-10px">Te rezervuara</span>
              {{ useMainSearchStore().amount_format(selected_search_result.product.reservation_stock_amount) }}
            </div>
            <div class="text-right px-2 cursor-pointer">
              <i v-if="expand_search_result" class="fa-solid fa-chevron-up"></i>
              <i v-else class="fa-solid fa-chevron-down"></i>
            </div>
          </div>

          <!-- Expanded search result -->
          <div v-if="expand_search_result"
            class="border-r border-l border-b border-slate-400 text-14px pt-2 flex-flex-col">
            <div class="flex flex-col">
              <!-- Table -->
              <table class="table is-narrow is-bordered">
                <thead>
                  <tr>
                    <th style="width: 20%;">POS</th>
                    <th style="width: 20%;">Stok i lire</th>
                    <th style="width: 20%;">Vetrine</th>
                    <th style="width: 20%;">P.demtuara</th>
                    <th style="width: 20%;">Te rezervuara</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ diary ? diary.pos_name : selected_pos_info.name }}
                    </td>
                    <td>
                      {{ useMainSearchStore().amount_format(selected_search_result.pos_free_stock) }}
                      <!-- <span v-if="selected_search_result.pos_free_stock > 0" @click="open_create_modal(1, null)"
                        class="border border-green-500 bg-green-100 text-white ml-1 cursor-pointer"> -->
                      <span v-if="selected_search_result.pos_free_stock > 0" 
                          @click="useMainSearchStore().add_to_cart(1, 1, null, selected_search_result)"
                        class="border border-green-500 bg-green-100 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-500 mx-1"></i>
                      </span>
                      <span v-else class="border border-green-500 bg-green-50 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-300 mx-1"></i>
                      </span>
                    </td>
                    <td>
                      {{ useMainSearchStore().amount_format(selected_search_result.pos_vetrine) }}
                      <span v-if="selected_search_result.pos_vetrine > 0" 
                        @click="useMainSearchStore().add_to_cart(1, 3, null, selected_search_result)"
                        class="border border-green-500 bg-green-100 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-500 mx-1"></i>
                      </span>
                      <span v-else class="border border-green-500 bg-green-50 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-300 mx-1"></i>
                      </span>
                    </td>
                    <td>
                      {{ useMainSearchStore().amount_format(selected_search_result.pos_damaged) }}
                      <span v-if="selected_search_result.pos_damaged > 0" @click="useMainSearchStore().add_to_cart(1, 4, null, selected_search_result)"
                        class="border border-green-500 bg-green-100 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-500 mx-1"></i>
                      </span>
                      <span v-else class="border border-green-500 bg-green-50 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-300 mx-1"></i>
                      </span>
                    </td>
                    <td>
                      {{ useMainSearchStore().amount_format(selected_search_result.reservation_stock_amount) }}
                      <span v-if="selected_search_result.reservation_stock_amount > 0" @click="useMainSearchStore().add_to_cart(1, 2, null, selected_search_result)"
                        class="border border-green-500 bg-green-100 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-500 mx-1"></i>
                      </span>
                      <span v-else class="border border-green-500 bg-green-50 text-white ml-1 cursor-pointer">
                        <i class="fa-solid fa-plus text-green-300 mx-1"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="show_more_section" class="flex flex-col py-2 border-t bg-slate-50 ">
              <!-- Table -->
              <table class="table is-narrow is-bordered">
                <thead>
                  <tr>
                    <th style="width: 20%;">Magazina</th>
                    <th style="width: 20%;">Stok i lire</th>
                    <th style="width: 20%;">Vetrine</th>
                    <th style="width: 20%;">P.demtuara</th>
                    <th style="width: 20%;">Te rezervuara</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <div v-for="(warehouse, index) in other_warehouses_grouped['Stok per shitje']" :key="index" class="flex flex-col mb-2">
                        <div class="flex items-center border-b">
                          <div @click="toggle_other_warehouse(warehouse)" class="flex flex-col flex-grow">
                            <!-- <span class="text-10px text-slate-500"> {{ warehouse.warehouse_facility }} </span> -->
                            <span class="text-12px">{{ warehouse.warehouse_name }}</span>
                          </div>
                          <div>
                            {{ useMainSearchStore().amount_format(warehouse.amount) }}
                          </div>
                          <div class="">
                            <span @click="useMainSearchStore().add_to_cart(0, 1, warehouse, selected_search_result)"
                              :class="[warehouse.amount == 0 ? 'bg-green-50 text-green-300' : 'bg-green-100 text-green-500']"
                              class="border border-green-500  text-white ml-1 cursor-pointer">
                              <i class="fa-solid fa-plus  mx-1"></i>
                            </span>
                          </div>
                        </div>
                        <div v-if="warehouse.expanded" class="bg-white border p-1">
                          <div v-if="warehouse.loading_expand">
                            Loading ...
                          </div>
                          <div v-else>
                            <div v-for="(pos, index) in warehouse.poses" :key="index"
                              class="flex items-center border-b border-slate-100 ">
                              <span class="flex-grow flex flex-col">
                                {{ pos.pos_name }}
                                <span class="flex text-slate-500 text-10px">
                                  <span v-if="pos.city_name" class=" mr-1">
                                    {{ pos.city_name }}
                                  </span>
                                  <span v-if="pos.pos_location" class=" mr-1">
                                    {{ pos.pos_location == 0 ? '' : pos.pos_location }}
                                  </span>
                                </span>
                              </span>
                              <span class="flex flex-col text-right">
                                <span class="text-10px">
                                  {{ pos.manager_name }}
                                </span>
                                <span class="text-10px">
                                  {{ pos.user_contact }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-for="(warehouse, index) in other_warehouses_grouped['Vetrine']" :key="index" class="flex flex-col ">
                        <div class="flex items-center border-b">
                          <div @click="toggle_other_warehouse(warehouse)" class="flex flex-col flex-grow">
                            <!-- <span class="text-10px text-slate-500"> {{ warehouse.warehouse_facility }} </span> -->
                            <span class="text-12px">{{ warehouse.warehouse_name }}</span>
                          </div>
                          <div>
                            {{ useMainSearchStore().amount_format(warehouse.amount) }}
                          </div>
                          <div class="">
                            <span @click="useMainSearchStore().add_to_cart(0, 1, warehouse, selected_search_result)"
                              :class="[warehouse.amount == 0 ? 'bg-green-50 text-green-300' : 'bg-green-100 text-green-500']"
                              class="border border-green-500  text-white ml-1 cursor-pointer">
                              <i class="fa-solid fa-plus  mx-1"></i>
                            </span>
                          </div>
                        </div>
                        <div v-if="warehouse.expanded" class="bg-white border p-1">
                          <div v-if="warehouse.loading_expand">
                            Loading ...
                          </div>
                          <div v-else>
                            <div v-for="(pos, index) in warehouse.poses" :key="index"
                              class="flex items-center border-b border-slate-100 ">
                              <span class="flex-grow flex flex-col">
                                {{ pos.pos_name }}
                                <span class="flex text-slate-500 text-10px">
                                  <span v-if="pos.city_name" class=" mr-1">
                                    {{ pos.city_name }}
                                  </span>
                                  <span v-if="pos.pos_location" class=" mr-1">
                                    {{ pos.pos_location == 0 ? '' : pos.pos_location }}
                                  </span>
                                </span>
                              </span>
                              <span class="flex flex-col text-right">
                                <span class="text-10px">
                                  {{ pos.manager_name }}
                                </span>
                                <span class="text-10px">
                                  {{ pos.user_contact }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-for="(warehouse, index) in other_warehouses_grouped['Produkte te demtuara']" :key="index" class="flex flex-col ">
                        <div class="flex items-center border-b">
                          <div @click="toggle_other_warehouse(warehouse)" class="flex flex-col flex-grow">
                            <!-- <span class="text-10px text-slate-500"> {{ warehouse.warehouse_facility }} </span> -->
                            <span class="text-12px">{{ warehouse.warehouse_name }}</span>
                          </div>
                          <div>
                            {{ useMainSearchStore().amount_format(warehouse.amount) }}
                          </div>
                          <div class="">
                            <span @click="useMainSearchStore().add_to_cart(0, 1, warehouse, selected_search_result)"
                              :class="[warehouse.amount == 0 ? 'bg-green-50 text-green-300' : 'bg-green-100 text-green-500']"
                              class="border border-green-500  text-white ml-1 cursor-pointer">
                              <i class="fa-solid fa-plus  mx-1"></i>
                            </span>
                          </div>
                        </div>
                        <div v-if="warehouse.expanded" class="bg-white border p-1">
                          <div v-if="warehouse.loading_expand">
                            Loading ...
                          </div>
                          <div v-else>
                            <div v-for="(pos, index) in warehouse.poses" :key="index"
                              class="flex items-center border-b border-slate-100 ">
                              <span class="flex-grow flex flex-col">
                                {{ pos.pos_name }}
                                <span class="flex text-slate-500 text-10px">
                                  <span v-if="pos.city_name" class=" mr-1">
                                    {{ pos.city_name }}
                                  </span>
                                  <span v-if="pos.pos_location" class=" mr-1">
                                    {{ pos.pos_location == 0 ? '' : pos.pos_location }}
                                  </span>
                                </span>
                              </span>
                              <span class="flex flex-col text-right">
                                <span class="text-10px">
                                  {{ pos.manager_name }}
                                </span>
                                <span class="text-10px">
                                  {{ pos.user_contact }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-for="(warehouse, index) in other_warehouses_grouped['Rezervime']" :key="index" class="flex flex-col ">
                        <div class="flex items-center border-b">
                          <div @click="toggle_other_warehouse(warehouse)" class="flex flex-col flex-grow">
                            <!-- <span class="text-10px text-slate-500"> {{ warehouse.warehouse_facility }} </span> -->
                            <span class="text-12px">{{ warehouse.warehouse_name }}</span>
                          </div>
                          <div>
                            {{ useMainSearchStore().amount_format(warehouse.amount) }}
                          </div>
                          <div class="">
                            <span @click="useMainSearchStore().add_to_cart(0, 1, warehouse, selected_search_result)"
                              :class="[warehouse.amount == 0 ? 'bg-green-50 text-green-300' : 'bg-green-100 text-green-500']"
                              class="border border-green-500  text-white ml-1 cursor-pointer">
                              <i class="fa-solid fa-plus  mx-1"></i>
                            </span>
                          </div>
                        </div>
                        <div v-if="warehouse.expanded" class="bg-white border p-1">
                          <div v-if="warehouse.loading_expand">
                            Loading ...
                          </div>
                          <div v-else>
                            <div v-for="(pos, index) in warehouse.poses" :key="index"
                              class="flex items-center border-b border-slate-100 ">
                              <span class="flex-grow flex flex-col">
                                {{ pos.pos_name }}
                                <span class="flex text-slate-500 text-10px">
                                  <span v-if="pos.city_name" class=" mr-1">
                                    {{ pos.city_name }}
                                  </span>
                                  <span v-if="pos.pos_location" class=" mr-1">
                                    {{ pos.pos_location == 0 ? '' : pos.pos_location }}
                                  </span>
                                </span>
                              </span>
                              <span class="flex flex-col text-right">
                                <span class="text-10px">
                                  {{ pos.manager_name }}
                                </span>
                                <span class="text-10px">
                                  {{ pos.user_contact }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div @click="show_more_results()" v-if="!show_more_section"
              class="p-1 text-center bg-slate-200 border-t border-slate-400 text-slate-500 cursor-pointer">
              Me shume
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_create_modal" @click.self="show_create_modal = false"
      class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center">
      <div class="bg-white flex-col w-2/3 mx-3 rounded overflow-y text-14px"
        style="max-height:calc(100vh - 75px); overflow-y:scroll;">
        <!-- Modal header -->
        <div class="p-2 border-b text-center flex justify-between items-center">
          Krijo ditar ose proces me produktin: {{ selected_search_result.product.name }}
        </div>
        <!-- /modal body  -->
        <div class="px-2 py-1">
          <div class="flex flex-col w-1/2">
            <label for="">Cfar doni te krijoni me kete produkt?</label>
            <select v-model="create_type" :disabled="true" class="border rounded">
              <option :value="1">Ditar</option>
              <option :value="2">Proces</option>
            </select>
          </div>
        </div>
        <div class="p-2 flex bg-slate-100">
          <a @click.prevent="create_main_search()" class="w-full bg-green-200 border rounded border-green-600 text-center"
            href="">Krijo</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import customerSearchTab from '@/pages/main_search/components/customer_search_tab.vue';

// customer-search-tab
// import Customer from "@/services/Customer";
export default {
  props: ['diary'],
  data() {
    return {
      current_user: {},
      posses_list: [],
      // selected_pos_id: "",
      show_search_results: false,
      selected_search_result: {},
      expand_search_result: false,
      show_more_section: false,
      other_warehouses: [],
      show_create_modal: false,
      create_type: 1,
      create_warehouse_type: 0,
      selected_other_warehouse: null,
      customers: []
    }
  },
  async created() {
    this.current_user = JSON.parse(localStorage.user)
    if(!this.diary){
      await Api(true).post('/invoice/get/assigned/pos', { user: this.current_user })
      .then(res => {
        this.posses_list = res.data
        if (res.data.length > 0) {
          useMainSearchStore().selected_pos_id = res.data[0].guid
          useMainSearchStore().selected_pos_name = res.data[0].name
          
          useMainSearchStore().selected_pos = res.data[0]
          useDiaryStore().process.origin = useMainSearchStore().selected_pos_id
        }
      })
    }
    else {
      useMainSearchStore().selected_pos_id = this.diary.pos_id
      useMainSearchStore().selected_pos_name = this.diary.pos_name
    }
    // this.customers = await Customer.getCustomers();
  },
  methods: {
    set_selected_pos() {
      this.selected_search_result = {}
      this.expand_search_result = false
      useDiaryStore().process.origin = useMainSearchStore().selected_pos_id
    },
    async select_result_product(result) {
      // Reset search
      useMainSearchStore().search_results = []
      useMainSearchStore().search_val = ""
      this.show_more_section = false
      this.expand_search_result = false
      this.selected_search_result.product = result
      if(this.diary?.guid){
        // Expand selected search result
        this.expand_result()
      }
    },
    async expand_result() {
      await Api(true).post('/mainsearch/expand/result', {
        product: this.selected_search_result.product,
        pos_id: useMainSearchStore().selected_pos_id
      })
        .then(res => {
          this.expand_search_result = !this.expand_search_result
          // get the results for the selected pos -> selected_pos_id
          if(res.data.length > 0){
              this.selected_search_result.pos_free_stock = res.data[0].free_stock_amount
              this.selected_search_result.pos_vetrine = res.data[0].showcase_amount
              this.selected_search_result.pos_reserved = res.data[0].reservation_stock_amount
              this.selected_search_result.pos_damaged = res.data[0].broken_stock_amount
          }
        })
    },
    async show_more_results() {
      await Api(true).post('/mainsearch/more/results', {
        product: this.selected_search_result.product,
        pos_id: useMainSearchStore().selected_pos_id
      })
        .then(res => {
          this.show_more_section = true
          this.other_warehouses = res.data.map(r => {
            r.expanded = false
            r.loading_expand = false
            r.poses = []
            return r
          })
        })
    },
    async toggle_other_warehouse(warehouse) {
      var expand = false
      this.other_warehouses = [...this.other_warehouses.map(w => {
        if (w.warehouse_id == warehouse.warehouse_id) {
          w.expanded = !w.expanded
          expand = true
          w.loading_expand = !w.loading_expand
        }
        return w
      })]
      if (expand) {
        await Api(true).post('/mainsearch/warehouse/posses', { warehouse })
          .then(res => {
            this.other_warehouses = [...this.other_warehouses.map(w => {
              if (w.warehouse_id == warehouse.warehouse_id) {
                w.poses = res.data
              }
              return w
            })]
          })
      }
      this.other_warehouses = [...this.other_warehouses.map(w => {
        if (w.warehouse_id == warehouse.warehouse_id) {
          w.loading_expand = false
        }
        return w
      })]
    },
    start_search() {
      if (useMainSearchStore().search_val.length >= 3) {
        // Reset search results
        this.expand_search_result = false
        this.selected_search_result = {}
        useMainSearchStore().search_product()
      }
    },
    create_diary(result) {
      // /diary/create/search/:product_id/:pos_id/:warehouse_id
      window.location.href = '/diary/create/search/'
        + result.product.product_id + '/'
        + useMainSearchStore().selected_pos_id + '/'
        + 'my_warehouse'
    },
    async open_create_modal(w_type, selected_other_warehouse) {
      if (selected_other_warehouse && selected_other_warehouse.amount == 0) return
      this.create_warehouse_type = w_type /* 1 2 3 9 */
      this.selected_other_warehouse = selected_other_warehouse

      if(this.diary){
        // Conform and call the API
        if (confirm("Doni te shtoni kete produkt ne ditar?")) {
          await Api(true).post('/mainsearch/create/entity', {
            create_type: this.create_type,
            product: {
              ...this.selected_search_result.product,
              is_active: 1
            },
            warehouse_type: this.create_warehouse_type,
            pos: useMainSearchStore().selected_pos_id,
            warehouse: this.selected_other_warehouse,
            included_in_pos: this.selected_other_warehouse ? null : 1,
            diary: this.diary
          }).then(res => {
            if(res.data.document){
              useDiaryStore().getDiary(this.diary.guid)
              this.$toast.success('Produkti u shtua me sukses.')
              // Close modal
              this.$emit("close_prod_modal")
            }
          })
        }
      } else {
        this.show_create_modal = true
      }
    },
    async create_main_search() {
      var type = this.create_type == 1 ? "ditar" : this.create_type == 2 ? "proces" : " "
      if (confirm("Doni te krijoni nje " + type + " te ri?")) {
        await Api(true).post('/mainsearch/create/entity', {
          create_type: this.create_type,
          product: {
            ...this.selected_search_result.product,
            is_active: 1
          },
          warehouse_type: this.create_warehouse_type,
          pos: useMainSearchStore().selected_pos_id,
          warehouse: this.selected_other_warehouse,
          included_in_pos: this.selected_other_warehouse ? null : 1
        }).then(response => {
          console.log(response)
          // Backup working
          // if (response.data.document) {
          //   if (this.create_type == 1) window.location.href = "/diary/get/" + response.data.document
          // }
        })
      }
    }
  },
  computed: {
    selected_pos_info() {
      let a = {}
      if (this.posses_list.length > 0 && useMainSearchStore().selected_pos_id) {
        a = this.posses_list.find(i => i.guid == useMainSearchStore().selected_pos_id)
      }
      return a
    },
    other_warehouses_grouped(){
      // warehouse_facility
      var a = {}
      this.other_warehouses.map(w => {
        if(!a[w.warehouse_facility]) a[w.warehouse_facility] = []
        a[w.warehouse_facility].push(w)
      })
      return a
    }
  }
}
</script>

<script setup>
// import { reactive, onMounted, onBeforeUnmount , ref, inject, watch } from 'vue'
import { onMounted } from 'vue'
// Sthis.$toastres import
// import { useSearchProcess } from '@/stores/search_process'
import { useDiaryStore } from '@/stores/diary'
import { useMainSearchStore } from '@/stores/main_search'
document.title = 'Kerko produkt'

onMounted(async () => {

})
</script>
<style></style>